/* eslint-disable no-undef */
/* eslint-disable prefer-promise-reject-errors */
const BaseUrl = 'https://around.yanivportfolio.com/api';

export const checkRes = async (res) =>
  res.ok
    ? res.json()
    : Promise.reject({
        status: res.status,
        message: await res.json().then((data) => data.error || data.message),
      });

export const register = async (email, password) =>
  fetch(`${BaseUrl}/signup`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((res) => checkRes(res));

export const login = async (email, password) =>
  fetch(`${BaseUrl}/signin`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((res) => checkRes(res));

export const logout = async () =>
  fetch(`${BaseUrl}/signout`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials: 'include',
  }).then(checkRes);

export const authorize = async () =>
  fetch(`${BaseUrl}/users/me`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
  }).then((res) => checkRes(res));
