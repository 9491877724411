/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import handleClick from '../utils/handleClick';

export default function ImagePopup({ selectedCard: { name, link }, isOpen, onClose }) {
  const clickHandler = (event) => handleClick(event, onClose);

  return (
    <div
      onClick={clickHandler}
      role="button"
      tabIndex={0}
      className={isOpen ? 'popup popup_type_image popup_opened' : 'popup popup_type_image'}
    >
      <div className="popup__container popup__container_type_image">
        <img className="popup__image" src={link} alt={name} />

        <h2 className="popup__image-title">{name}</h2>

        <button
          className="popup__close-button"
          type="button"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
    </div>
  );
}
ImagePopup.propTypes = {
  selectedCard: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
