import React, { useState, useRef, useCallback } from 'react';
import escape from 'escape-html';
import PropTypes from 'prop-types';
import { testUrl } from '../utils/regex';
import PopupWithForm from './PopupWithForm';

export default function EditAvatarPopup({ isOpen, onClose, onUpdateAvatar }) {
  const [isLoading, setIsLoading] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const [url, setUrl] = useState('');

  const [urlError, setUrlError] = useState('');

  const formRef = useRef();

  const handleClose = useCallback(() => {
    onClose();

    setUrl('');

    setUrlError('');

    setFormValid(false);
  }, [onClose]);

  const handleUrlInputChange = (event) => {
    const input = event.target.value;
    const result = testUrl(input);
    setUrl(input);

    if (result.valid) {
      setUrlError('');

      setFormValid(true);
    } else {
      setUrlError('Please fill in a valid URL');

      setFormValid(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formValid) {
      setIsLoading(true);

      const escapedInput = escape(url);

      await onUpdateAvatar(escapedInput);

      handleClose();

      setTimeout(() => setIsLoading(false), 500);
    }
  };

  return (
    <PopupWithForm
      name="edit-picture"
      formName="editPicture"
      formTitle="Change profile picture"
      submitTitle="Save"
      submitLoadingTitle="Saving"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      formRef={formRef}
      formValid={formValid}
      isLoading={isLoading}
      handleClose={handleClose}
    >
      <input
        onChange={handleUrlInputChange}
        value={url}
        className="popup__input popup__input_type_profile-picture"
        id="profile-picture-input"
        placeholder="profile picture url"
        name="picture"
        type="url"
        required
      />

      <span className="popup__error profile-picture-input-error">{urlError}</span>
    </PopupWithForm>
  );
}
EditAvatarPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateAvatar: PropTypes.func.isRequired,
};
