/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { checkRes } from './auth';

/** @module Api */

/**
 * @class
 * @classdesc Make requests to the website API with the class methods
 * @constructor
 * @param {object} - An object with the base URL of the API and the authorization key
 * @param {string} - The base URL of the API
 */

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
  }

  /**
   * @method getIntialCard
   * @description A public method to get the intial cards from the API
   * @returns The result from the API
   * @public
   */

  getIntialCard() {
    return fetch(`${this._baseUrl}/cards`, {
      credentials: 'include',
    }).then((res) => checkRes(res));
  }

  /**
   * @method setUserInfo
   * @description A public method to set the user info in the API
   * @param {object} - An object with the name and about info for the user
   * @param {string} name - The user name
   * @param {string} about - The user about info
   * @returns The result from the API
   * @public
   */

  setUserinfo({ name, about }) {
    return fetch(`${this._baseUrl}/users/me`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        about,
      }),
    }).then((res) => checkRes(res));
  }

  /**
   * @method setUserPicture
   * @description A public method to set a new user picture in the API
   * @param {string} url - The url for the user picture
   * @returns The result from the API
   * @public
   */

  setUserPicture(url) {
    return fetch(`${this._baseUrl}/users/me/avatar`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: url,
      }),
    }).then((res) => checkRes(res));
  }

  /**
   * @method addCard
   * @description A public method to add a new image card to the API
   * @param {object} - An object with the name and link for the image card
   * @param {string} name - The title for the image card
   * @param {string} link - The url for the image
   * @returns The result from the API
   * @public
   */

  addCard({ name, link }) {
    return fetch(`${this._baseUrl}/cards`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        link,
      }),
    }).then((res) => checkRes(res));
  }

  /**
   * @method deleteCard
   * @description A public method to delete a card from the API
   * @param {string} _id - The id of the card
   * @returns The result from the API
   * @public
   */

  deleteCard(_id) {
    return fetch(`${this._baseUrl}/cards/${_id}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((res) => checkRes(res));
  }

  /**
   * @method addLike
   * @description A public method to add a card like in the API
   * @param {string} _id - The id of the card
   * @returns The result from the API
   * @public
   */

  addLike(_id) {
    return fetch(`${this._baseUrl}/cards/${_id}/likes`, {
      method: 'PUT',
      credentials: 'include',
    }).then((res) => checkRes(res));
  }

  /**
   * @method removeLike
   * @description A public method to remove a card like from the API
   * @param {string} _id - The id of the card
   * @returns The result from the API
   * @public
   */

  removeLike(_id) {
    return fetch(`${this._baseUrl}/cards/${_id}/likes`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((res) => checkRes(res));
  }
}

const api = new Api({
  baseUrl: 'https://around.yanivportfolio.com/api',
});

export default api;
