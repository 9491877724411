import { testEmail, testStrength, testValid } from './regex';

export const handleEmailChange = (event, setEmail, setEmailError) => {
  const email = event.target.value;

  setEmail(email);

  const emailValid = testEmail(email);

  if (emailValid.valid) {
    setEmailError({
      isValid: true,
      inputError: '',
    });

    setEmail(emailValid.match[0]);
  } else {
    setEmailError({
      isValid: false,
      inputError: 'Please fill in a valid Email.',
    });
  }
};

export const handlePasswordChange = (event, setPassword, setPasswordError, options) => {
  const password = event.target.value;
  setPassword(password);
  if (password.length < 6) {
    setPasswordError({
      isValid: false,
      inputError: 'Password must be at least 6 characters.',
    });
    return;
  }

  if (password.length > 20) {
    setPasswordError({
      isValid: false,
      inputError: "Password can't be longer than 20 characters.",
    });
    return;
  }
  if (testValid(password)) {
    if (options === 'login') {
      setPasswordError({
        isValid: true,
        inputError: '',
      });
      return;
    }
    if (testStrength(password)) {
      setPasswordError({
        isValid: true,
        inputError: '',
      });
      return;
    }
    setPasswordError({
      isValid: false,
      inputError:
        'Please include at least 1 uppercase character, 1 lowercase character, and 1 number.',
    });
    return;
  }
  setPasswordError({
    isValid: false,
    inputError: "'<' and '>' are invalid characters.",
  });
};
