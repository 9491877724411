import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormPage from './FormPage';
import { handleEmailChange, handlePasswordChange } from '../utils/form';
import { login } from '../utils/auth';

export default function Login({
  setHeaderText,
  setHeaderButton,
  setHeaderAction,
  setLoggedIn,
  isLoading,
  setIsLoading,
  setInfoTooltipMessage,
  setInfoTooltipOk,
  setInfoToolTipOpen,
}) {
  const navigate = useNavigate();

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState({
    isValid: false,
    inputError: '',
  });

  const [passwordError, setPasswordError] = useState({
    isValid: false,
    inputError: '',
  });

  const handleEmailInput = (event) => handleEmailChange(event, setEmail, setEmailError);

  const handlePasswordInput = (event) =>
    handlePasswordChange(event, setPassword, setPasswordError, 'login');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formValid) {
      setIsLoadingLogin(true);
      setFormValid(false);
      login(email, password)
        .then((res) => {
          if (res.email) {
            setLoggedIn(true);
            setIsLoading(true);
            navigate('/');
          } else throw new Error(res.message || res.err);
        })
        .catch((err) => {
          setInfoTooltipOk(false);
          setInfoTooltipMessage(err.message);
          setInfoToolTipOpen(true);
        })
        .finally(() => setIsLoadingLogin(false));
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  useEffect(() => {
    if (emailError.isValid && passwordError.isValid) setFormValid(true);
    else setFormValid(false);
  }, [emailError, passwordError]);

  useEffect(() => {
    setHeaderText('');

    setHeaderButton('Sign up');

    setHeaderAction({ action: handleRegisterRedirect });

    return () => setHeaderAction({});
  }, []);

  return (
    <main className="container">
      <div className={isLoading ? 'spinner' : 'spinner spinner_hidden'}>
        <i />
      </div>

      <div className={isLoading ? 'content content_hidden' : 'content'}>
        <section className="login">
          <FormPage
            isLoading={isLoadingLogin}
            formValid={formValid}
            formName="login"
            formTitle="Log in"
            submitTitle="Log in"
            submitLoadingTitle="Loging in"
            handleSubmit={handleSubmit}
          >
            <input
              className="form__input"
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailInput}
            />

            <span className="form__error">{emailError.inputError}</span>

            <input
              className="form__input"
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordInput}
            />

            <span className="form__error">{passwordError.inputError}</span>

            <p className="form__error" />
          </FormPage>

          <button
            className="login__register-text-button"
            type="button"
            onClick={handleRegisterRedirect}
          >
            Not a member yet? Sign up here!
          </button>
        </section>
      </div>
    </main>
  );
}
Login.propTypes = {
  setHeaderText: PropTypes.func.isRequired,
  setHeaderButton: PropTypes.func.isRequired,
  setHeaderAction: PropTypes.func.isRequired,
  setLoggedIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setInfoTooltipMessage: PropTypes.func.isRequired,
  setInfoTooltipOk: PropTypes.func.isRequired,
  setInfoToolTipOpen: PropTypes.func.isRequired,
};
