/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import handleClick from '../utils/handleClick';

export default function PopupWithForm({
  name,
  formName,
  formTitle,
  submitTitle,
  submitLoadingTitle,
  isOpen,
  onSubmit,
  formRef,
  formValid,
  isLoading,
  handleClose,
  children,
}) {
  const clickHandler = (event) => handleClick(event, handleClose);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={clickHandler}
      className={isOpen ? `popup popup_type_${name} popup_opened` : `popup popup_type_${name}`}
    >
      <div className="popup__container">
        <form
          ref={formRef}
          className="popup__form"
          name={formName}
          _id=""
          onSubmit={onSubmit}
          noValidate
        >
          <h2 className="popup__title">{formTitle}</h2>

          {children}

          <button
            className={
              formValid ? 'popup__save-button' : 'popup__save-button popup__save-button_disabled'
            }
            type="submit"
          >
            {isLoading ? `${submitLoadingTitle}...` : submitTitle}
          </button>
        </form>

        <button
          className="popup__close-button"
          type="button"
          aria-label="Close"
          onClick={handleClose}
        />
      </div>
    </div>
  );
}
PopupWithForm.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  submitTitle: PropTypes.string.isRequired,
  submitLoadingTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formRef: PropTypes.objectOf(PropTypes.object).isRequired,
  formValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.object),
};
PopupWithForm.defaultProps = {
  children: [],
};
