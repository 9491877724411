import React from 'react';
import PropTypes from 'prop-types';

export default function FormPage({
  isLoading,
  formValid,
  formName,
  formTitle,
  submitTitle,
  submitLoadingTitle,
  handleSubmit,
  children,
}) {
  return (
    <form className="form" name={formName} onSubmit={handleSubmit} noValidate>
      <h1 className="form__title">{formTitle}</h1>

      <div className="form__input-container">{children}</div>

      <button
        className={formValid ? 'form__save-button' : 'form__save-button form__save-button_disabled'}
        type="submit"
      >
        {isLoading ? `${submitLoadingTitle}...` : submitTitle}
      </button>
    </form>
  );
}
FormPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  submitTitle: PropTypes.string.isRequired,
  submitLoadingTitle: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
