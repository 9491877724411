/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import failIcon from '../images/fail-register.svg';
import successIcon from '../images/success-register.svg';
import handleClick from '../utils/handleClick';

export default function InfoTooltip({ isOk, isOpen, onClose, message }) {
  const clickHandler = (event) => handleClick(event, onClose);

  return (
    <div
      onClick={clickHandler}
      role="button"
      tabIndex={0}
      className={isOpen ? `popup popup_type_tooltip popup_opened` : `popup popup_type_tooltip`}
    >
      <div className="popup__container">
        <div className="popup__message-container">
          {isOk ? (
            <img className="popup__icon" src={successIcon} alt="Success register" />
          ) : (
            <img className="popup__icon" src={failIcon} alt="Fail register" />
          )}

          <p className="popup__message">
            {message || 'Oops, Something went wrong! Please try again.'}
          </p>
        </div>

        <button
          className="popup__close-button"
          type="button"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
    </div>
  );
}
InfoTooltip.propTypes = {
  isOk: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
