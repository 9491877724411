import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { testUrl, testValid } from '../utils/regex';
import PopupWithForm from './PopupWithForm';

export default function AddPlacePopup({ isOpen, onClose, onAddPlace }) {
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const [nameError, setNameError] = useState({
    isValid: false,
    inputError: '',
  });

  const [urlError, setUrlError] = useState({
    isValid: false,
    inputError: '',
  });

  const [name, setName] = useState('');

  const [link, setLink] = useState('');

  const handleClose = useCallback(() => {
    onClose();

    setName('');

    setLink('');

    setUrlError({
      isValid: false,
      inputError: '',
    });

    setNameError({
      isValid: false,
      inputError: '',
    });
  }, [onClose]);

  const handleNameInput = (event) => {
    const input = event.target.value;

    setName(input);

    if (input.length < 2) {
      setNameError({
        isValid: false,
        inputError: 'Use at least 2 characters',
      });

      return;
    }

    if (input.length > 30) {
      setNameError({
        isValid: false,
        inputError: 'Use no more than 30 characters',
      });

      return;
    }
    if (testValid(input)) {
      setNameError({
        isValid: true,
        inputError: '',
      });
    } else {
      setNameError({
        isValid: false,
        inputError: `'<' and '>' are invalid characters`,
      });
    }
  };

  const handleLinkInput = (event) => {
    const input = event.target.value;

    setLink(input);

    const result = testUrl(input);

    if (result.valid) {
      setUrlError({
        isValid: true,
        inputError: '',
      });

      setLink(result.match[0]);
    } else {
      setUrlError({
        isValid: false,
        inputError: 'Please fill in a valid URL',
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formValid) {
      setIsLoading(true);

      await onAddPlace(name, link);

      handleClose();

      setTimeout(() => setIsLoading(false), 500);
    }
  };

  useEffect(() => {
    if (nameError.isValid && urlError.isValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [nameError, urlError]);

  return (
    <PopupWithForm
      name="add"
      formName="add"
      formTitle="New Place"
      submitTitle="Create"
      submitLoadingTitle="Creating"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      formRef={formRef}
      formValid={formValid}
      isLoading={isLoading}
      handleClose={handleClose}
    >
      <input
        value={name}
        onChange={handleNameInput}
        className="popup__input popup__input_type_title"
        id="title-input"
        type="text"
        placeholder="Title"
        name="title"
        required
      />

      <span className="popup__error  title-input-error">{nameError.inputError}</span>

      <input
        value={link}
        onChange={handleLinkInput}
        className="popup__input popup__input_type_url"
        id="url-input"
        type="url"
        placeholder="Image URL"
        name="url"
        required
      />

      <span className="popup__error url-input-error">{urlError.inputError}</span>
    </PopupWithForm>
  );
}
AddPlacePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddPlace: PropTypes.func.isRequired,
};
