/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import ImagePopup from './ImagePopup';
import api from '../utils/api';
import CurrentUserContext from '../contexts/CurrentUserContext';
import EditProfilePopup from './EditProfilePopup';
import EditAvatarPopup from './EditAvatarPopup';
import AddPlacePopup from './AddPlacePopup';
import DeleteCardPopup from './DeleteCardPopup';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import Register from './Register';
import { authorize } from '../utils/auth';
import InfoTooltip from './InfoTooltip';

function App() {
  const [likeActive, setLikeActive] = useState(false);

  const [headerText, setHeaderText] = useState('');

  const [headerButton, setHeaderButton] = useState('');

  const [headerAction, setHeaderAction] = useState({});

  const [headerStyle, setHeaderStyle] = useState({});

  const [currentUser, setCurrentUser] = useState({});

  const [selectedCard, setSelectedCard] = useState({
    name: '',
    link: '',
  });

  const [deleteCardId, setDeleteCardId] = useState('');

  const [isEditInfoPopupOpen, setIsEditIntoPopupOpen] = useState(false);

  const [isAddPlacePopupOpen, setIsAddPlacePopupOpen] = useState(false);

  const [isDeleteCardPopupOpen, setIsDeleteCardPopupOpen] = useState(false);

  const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] = useState(false);

  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  const [isInfoTooltipOpen, setIsInfoTooltipOpen] = useState(false);

  const [infoTooltipOk, setInfoTooltipOk] = useState(false);

  const [infoTooltipMessage, setInfoTooltipMessage] = useState('');

  const [loggedIn, setLoggedIn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [cards, setCards] = useState([]);

  const handleEditAvatarClick = () => {
    setIsEditAvatarPopupOpen(true);
  };

  const handleEditProfileClick = () => {
    setIsEditIntoPopupOpen(true);
  };

  const handleAddPlaceClick = () => {
    setIsAddPlacePopupOpen(true);
  };

  const handleDeleteCardClick = (cardId) => {
    setDeleteCardId(cardId);
    setIsDeleteCardPopupOpen(true);
  };

  const handleCardClick = (name, link) => {
    setSelectedCard({
      name,
      link,
    });
    setIsImagePopupOpen(true);
  };

  const closeAllPopups = useCallback(() => {
    setIsEditAvatarPopupOpen(false);
    setIsEditIntoPopupOpen(false);
    setIsAddPlacePopupOpen(false);
    setIsImagePopupOpen(false);
    setIsDeleteCardPopupOpen(false);
    setIsInfoTooltipOpen(false);
  }, []);

  useEffect(() => {
    const closeByEscape = (e) => {
      if (e.key === 'Escape') {
        closeAllPopups();
      }
    };

    document.addEventListener('keydown', closeByEscape);

    return () => document.removeEventListener('keydown', closeByEscape);
  }, []);

  const handleUpdateUser = async (name, about) => {
    await api
      .setUserinfo({ name, about })
      .then(({ user }) => {
        setCurrentUser(user);
        closeAllPopups();
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateAvatar = async (link) => {
    await api
      .setUserPicture(link)
      .then(() => {
        setCurrentUser({
          ...currentUser,
          link,
        });
        closeAllPopups();
      })
      .catch((err) => console.log(err));
  };

  const handleAddPlace = async (name, link) => {
    await api
      .addCard({ name, link })
      .then(({ card }) => {
        setCards([card, ...cards]);
        closeAllPopups();
      })
      .catch((err) => console.log(err));
  };

  const setNewCard = (_id, newCard) => {
    setCards((state) => state.map((card) => (card._id === _id ? newCard : card)));
  };

  const handleCardLike = (_id, likes) => {
    setLikeActive(true);
    const isLiked = likes.some((like) => like === currentUser._id);

    if (isLiked) {
      api
        .removeLike(_id)
        .then((res) => setNewCard(_id, res.card))
        .catch((err) => console.log(err))
        .finally(() => setLikeActive(false));
      return;
    }
    api
      .addLike(_id)
      .then((res) => setNewCard(_id, res.card))
      .catch((err) => console.log(err))
      .finally(() => setLikeActive(false));
  };

  const handleCardDelete = async (event) => {
    event.preventDefault();

    await api
      .deleteCard(deleteCardId)
      .then(() => {
        setCards((state) => state.filter((card) => card._id !== deleteCardId));
        closeAllPopups();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!loggedIn || !currentUser._id) {
      setIsLoading(true);
      authorize()
        .then(async (res) => {
          await api
            .getIntialCard()
            .then((initialCards) => {
              setCurrentUser(res);
              setCards(initialCards);
              setLoggedIn(true);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [loggedIn]);

  return (
    <div className="page">
      <CurrentUserContext.Provider value={currentUser}>
        <Header
          headerText={headerText}
          headerButton={headerButton}
          headerAction={headerAction}
          headerStyle={headerStyle}
        />

        <Routes>
          <Route
            path="/login"
            element={
              loggedIn ? (
                <Navigate to="/" />
              ) : (
                <Login
                  setHeaderText={setHeaderText}
                  setHeaderButton={setHeaderButton}
                  setHeaderAction={setHeaderAction}
                  setLoggedIn={setLoggedIn}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setInfoTooltipMessage={setInfoTooltipMessage}
                  setInfoTooltipOk={setInfoTooltipOk}
                  setInfoToolTipOpen={setIsInfoTooltipOpen}
                />
              )
            }
          />
          <Route
            path="/register"
            element={
              loggedIn ? (
                <Navigate to="/" />
              ) : (
                <Register
                  setHeaderText={setHeaderText}
                  setHeaderButton={setHeaderButton}
                  setHeaderAction={setHeaderAction}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setInfoTooltipMessage={setInfoTooltipMessage}
                  setInfoTooltipOk={setInfoTooltipOk}
                  setInfoToolTipOpen={setIsInfoTooltipOpen}
                />
              )
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute loggedIn={loggedIn}>
                <Main
                  setHeaderText={setHeaderText}
                  setHeaderButton={setHeaderButton}
                  setHeaderAction={setHeaderAction}
                  setHeaderStyle={setHeaderStyle}
                  setLoggedIn={setLoggedIn}
                  isLoading={isLoading}
                  cards={cards}
                  setCards={setCards}
                  likeActive={likeActive}
                  onEditProfileClick={handleEditProfileClick}
                  onAddPlaceClick={handleAddPlaceClick}
                  onEditAvatarClick={handleEditAvatarClick}
                  onDeleteCardClick={handleDeleteCardClick}
                  onCardClick={handleCardClick}
                  onCardLike={handleCardLike}
                  setCurrentUser={setCurrentUser}
                />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={loggedIn ? <Navigate to="/" /> : <Navigate to="/login" />} />
        </Routes>

        <Footer />

        <EditProfilePopup
          isOpen={isEditInfoPopupOpen}
          onClose={closeAllPopups}
          onUpdateUser={handleUpdateUser}
        />

        <EditAvatarPopup
          isOpen={isEditAvatarPopupOpen}
          onClose={closeAllPopups}
          onUpdateAvatar={handleUpdateAvatar}
        />

        <AddPlacePopup
          isOpen={isAddPlacePopupOpen}
          onClose={closeAllPopups}
          onAddPlace={handleAddPlace}
        />

        <DeleteCardPopup
          isOpen={isDeleteCardPopupOpen}
          onClose={closeAllPopups}
          onDeleteCard={handleCardDelete}
        />

        <ImagePopup
          isOpen={isImagePopupOpen}
          selectedCard={selectedCard}
          onClose={closeAllPopups}
        />

        <InfoTooltip
          isOpen={isInfoTooltipOpen}
          onClose={closeAllPopups}
          isOk={infoTooltipOk}
          message={infoTooltipMessage}
        />
      </CurrentUserContext.Provider>
    </div>
  );
}

export default App;
