import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from './Card';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { authorize, logout } from '../utils/auth';

export default function Main({
  isLoading,
  cards,
  onEditProfileClick,
  onAddPlaceClick,
  onEditAvatarClick,
  onDeleteCardClick,
  onCardClick,
  onCardLike,
  likeActive,
  setHeaderText,
  setHeaderButton,
  setHeaderAction,
  setHeaderStyle,
  setLoggedIn,
  setCurrentUser,
}) {
  const navigate = useNavigate();

  const currentUser = useContext(CurrentUserContext);

  const { name, about, link } = currentUser;

  const handleLogout = () => {
    logout().then(() => {
      setLoggedIn(false);
      setCurrentUser({});
      navigate('/');
    });
  };

  const setHeader = () => {
    setLoggedIn(true);

    setHeaderButton('Log out');

    setHeaderStyle({ color: '#A9A9A9' });

    setHeaderAction({ action: handleLogout });
  };

  useEffect(() => {
    if (!currentUser.email || currentUser.email === '') {
      authorize()
        .then((res) => {
          setCurrentUser(res);

          setHeaderText(res.email);

          setHeader();
        })
        .catch(() => {
          navigate('/login');
        });
      return;
    }
    setHeaderText(currentUser.email);

    setHeader();
  }, []);

  return (
    <main className="container">
      <div className={isLoading ? 'spinner' : 'spinner spinner_hidden'}>
        <i />
      </div>

      <div className={isLoading ? 'content content_hidden' : 'content'}>
        <section className="profile">
          <button className="profile__edit-pic" type="button" onClick={onEditAvatarClick}>
            <div className="profile__pic" style={{ backgroundImage: `url(${link})` }} />
          </button>

          <div className="profile__info">
            <h1 className="profile__name" id="name">
              {name}
            </h1>

            <p className="profile__description" id="job">
              {about}
            </p>

            <button
              className="profile__edit-button"
              type="button"
              aria-label="Edit profile"
              onClick={onEditProfileClick}
            />
          </div>

          <button
            className="profile__add-button"
            type="button"
            aria-label="Add a picture"
            onClick={onAddPlaceClick}
          />
        </section>

        <section className="photos">
          <ul className="photos__grid">
            {cards.map((card) => (
              <Card
                card={card}
                key={card._id}
                onCardClick={onCardClick}
                onLike={onCardLike}
                likeActive={likeActive}
                onDelete={onDeleteCardClick}
              />
            ))}
          </ul>
        </section>
      </div>
    </main>
  );
}
Main.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditProfileClick: PropTypes.func.isRequired,
  onAddPlaceClick: PropTypes.func.isRequired,
  onEditAvatarClick: PropTypes.func.isRequired,
  onDeleteCardClick: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onCardLike: PropTypes.func.isRequired,
  likeActive: PropTypes.bool.isRequired,
  setHeaderText: PropTypes.func.isRequired,
  setHeaderButton: PropTypes.func.isRequired,
  setHeaderAction: PropTypes.func.isRequired,
  setHeaderStyle: PropTypes.func.isRequired,
  setLoggedIn: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};
