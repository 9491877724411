/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import logo from '../images/header-logo.svg';
import mobileMenu from '../images/mobile-menu.svg';
import closeIcon from '../images/close-icon.png';

export default function Header({ headerText, headerButton, headerAction, headerStyle }) {
  const mobileQuery = useMediaQuery({ query: '(max-width: 652px)' });

  const [menuIcon, setMenuIcon] = useState(mobileMenu);

  const [menuOpen, isMenuOpen] = useState(false);

  const handleClick = () => {
    isMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      setMenuIcon(closeIcon);
    } else {
      setMenuIcon(mobileMenu);
    }
  }, [menuOpen]);

  return (
    <header className="header">
      <div className="header__icons" style={menuOpen ? { alignItems: 'center' } : {}}>
        <img
          className="header__logo"
          src={logo}
          alt="Header logo"
          style={menuOpen ? { marginTop: '32px' } : {}}
        />

        {mobileQuery && (
          <img
            className="header__menu-icon"
            src={menuIcon}
            alt="mobile menu icon"
            onClick={handleClick}
            style={menuIcon === closeIcon ? { height: '38px', width: '38px' } : {}}
          />
        )}
      </div>

      {mobileQuery &&
        (menuOpen ? (
          <p className="header__text">
            {headerText}
            <span
              className="header__button"
              role="button"
              tabIndex={0}
              onClick={headerAction.action}
              style={headerStyle}
            >
              {' '}
              {headerButton}
            </span>
          </p>
        ) : (
          ''
        ))}
      {!mobileQuery && (
        <p className="header__text">
          {headerText}
          <span
            className="header__button"
            role="button"
            tabIndex={0}
            onClick={headerAction.action}
            style={headerStyle}
          >
            {' '}
            {headerButton}
          </span>
        </p>
      )}
    </header>
  );
}
Header.propTypes = {
  headerText: PropTypes.string,
  headerButton: PropTypes.string.isRequired,
  headerAction: PropTypes.objectOf(PropTypes.func).isRequired,
  headerStyle: PropTypes.objectOf(PropTypes.string).isRequired,
};
Header.defaultProps = {
  headerText: '',
};
