import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PopupWithForm from './PopupWithForm';

export default function DeleteCardPopup({ isOpen, onClose, onDeleteCard }) {
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoading(true);

    await onDeleteCard(event);

    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <PopupWithForm
      isOpen={isOpen}
      onSubmit={handleSubmit}
      name="delete"
      formName="delete"
      formTitle="Are you sure?"
      submitTitle="Yes"
      submitLoadingTitle="Deleting"
      formRef={formRef}
      formValid
      isLoading={isLoading}
      handleClose={onClose}
    />
  );
}
DeleteCardPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
};
